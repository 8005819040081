import carIcon from "@/assets/car-icon.svg";
import SelectedCard from "./SelectedCard";
import { useState, useEffect } from "react";
import GoogleMapReact from "google-map-react";
import truckIcon from "@/assets/truck-icon.svg";
import MarkerClusterer from "@google/markerclusterer";
import containerIcon from "@/assets/container-icon.svg";

// Map Component
// -------------------------------------------------------------------- //
export default function Map({
  locations,
  center,
  zoom,
  selectedAsset,
  setSelectedAsset,
}) {
  // state
  const [map, setMap] = useState(null);
  const [maps, setMaps] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [markerClusterer, setMarkerClusterer] = useState(null);
  const [mapType, setMapType] = useState("roadmap"); // Default to roadmap

  // map props
  const defaultProps = {
    center: {
      lat: center ? center.latitude : -1.5,
      lng: center ? center.longitude : 35.5,
    },
    zoom: zoom ? zoom : 6.5,
  };

  const onGoogleApiLoaded = ({ map, maps }) => {
    setMap(map);
    setMaps(maps);
  };

  const iconUrlMapping = {
    car: carIcon,
    truck: truckIcon,
  };

  // create markers from truck data
  useEffect(() => {
    if (map && maps) {
      // Clear existing markers and clusterer
      if (markerClusterer) {
        markerClusterer.clearMarkers();
      }
      markers.forEach((marker) => marker.setMap(null));
      setMarkers([]);

      if (selectedAsset) {
        // Determine the icon based on the asset source
        const iconUrl =
          selectedAsset.source === "vehicle"
            ? iconUrlMapping[selectedAsset.vehicle_type]
            : containerIcon;

        // If selectedTruck is not null, create and display only the selected truck's marker
        const selectedAssetPosition = {
          lat: selectedAsset.latitude,
          lng: selectedAsset.longitude,
        };

        const mapCenterPosition = {
          lat: selectedAsset.latitude,
          lng: selectedAsset.longitude + 0.05,
        };

        const marker = new maps.Marker({
          position: selectedAssetPosition,
          map: map,
          label: selectedAsset.truck,
          icon: {
            url: iconUrl,
            scaledSize: new maps.Size(30, 30),
            labelOrigin: new maps.Point(15, 40),
          },
        });

        // Center the map on the selected truck and zoom in
        map.setCenter(mapCenterPosition);
        map.setZoom(12); // Zoom level can be adjusted based on preference

        setMarkers([marker]);
      } else if (locations.length > 0) {
        // Restore the default zoom level if no truck is selected
        map.setZoom(defaultProps.zoom);

        // Create markers for all trucks and use the clusterer
        const newMarkers = locations.map((location) => {
          // Determine the icon based on the location source
          const iconUrl =
            location.source === "vehicle"
              ? iconUrlMapping[location.vehicle_type]
              : containerIcon;

          const marker = new maps.Marker({
            position: { lat: location.latitude, lng: location.longitude },
            label: location.registration_number,
            icon: {
              url: iconUrl,
              scaledSize: new maps.Size(30, 30),
              labelOrigin: new maps.Point(15, 40),
            },
          });

          marker.addListener("click", () => {
            setSelectedAsset(location);
          });

          return marker;
        });

        // Create a new clusterer with the new markers
        const newMarkerClusterer = new MarkerClusterer(map, newMarkers, {
          imagePath:
            "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
        });

        setMarkers(newMarkers);
        setMarkerClusterer(newMarkerClusterer);
      } else {
        // Restore the default zoom level and center if there are no locations
        map.setCenter(defaultProps.center);
        map.setZoom(defaultProps.zoom);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, maps, locations, selectedAsset]);

  return (
    <div className="w-full h-full pl-4 relative">
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyDHS1vzHhthX0r8z6NARZ8ofngg9boXHIU" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={onGoogleApiLoaded}
        options={{
          mapTypeId: mapType,
          mapId: "79ba85b61acfbcf1",
          disableDefaultUI: true,
        }}
      >
        {selectedAsset && (
          <div
            lat={selectedAsset.latitude}
            lng={selectedAsset.longitude}
            className="arrow-container bg-white text-blue-600 rounded-full p-2 shadow-md border border-1"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "40px", // Example size, adjust as needed
              height: "40px", // Example size, adjust as needed
              transform: `rotate(${selectedAsset.angle}deg)`,
            }}
          >
            <svg
              viewBox="0 0 24 24"
              width="36"
              height="36"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-arrow-up"
            >
              <line x1="12" y1="19" x2="12" y2="5"></line>
              <polyline points="5 12 12 5 19 12"></polyline>
            </svg>
          </div>
        )}
      </GoogleMapReact>
      {selectedAsset && <SelectedCard selectedAsset={selectedAsset} />}

      <div
        style={{
          position: "absolute",
          top: 10,
          left: 30,
          zIndex: 5,
        }}
      >
        <select
          value={mapType}
          onChange={(e) => setMapType(e.target.value)}
          style={{
            padding: "10px", // Add more padding inside the select
            border: "2px solid #bbbbbb", // A solid border with a custom color
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Add a subtle shadow
            borderRadius: "5px", // Optional: if you want rounded corners
            backgroundColor: "white", // Ensures the background is white
            cursor: "pointer", // Changes the cursor to indicate it's clickable
          }}
        >
          <option value="roadmap">Default</option>
          <option value="satellite">Satellite</option>
          <option value="hybrid">Hybrid</option>
          <option value="terrain">Terrain</option>
        </select>
      </div>
    </div>
  );
}
