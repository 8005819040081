import { createContext, useContext, useState, useEffect } from "react";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  // Get the initial authentication state from the localStorage
  const isInitiallyAuthenticated = !!localStorage.getItem("access_token");

  // Initialize isAuthenticated with the value from localStorage
  const [isAuthenticated, setIsAuthenticated] = useState(
    isInitiallyAuthenticated
  );

  const setAuthInfo = ({ token, firstName, isStaff }) => {
    localStorage.setItem("access_token", token);
    localStorage.setItem("first_name", firstName);
    localStorage.setItem("is_staff", isStaff);
    setIsAuthenticated(true);
  };

  const removeAuthInfo = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("first_name");
    localStorage.removeItem("is_staff");

    setIsAuthenticated(false);
  };

  const checkAuthentication = () => {
    const token = localStorage.getItem("access_token");
    setIsAuthenticated(!!token);
    return !!token;
  };

  // Ensure the auth state is updated when the component mounts
  useEffect(() => {
    checkAuthentication();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setAuthInfo,
        removeAuthInfo,
        checkAuthentication,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
