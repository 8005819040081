import { useState } from "react";
import TrackingMap from "./TrackingMap";
import TrackingPanel from "./TrackingPanel";
import MapLayout from "@/layouts/MapLayout";
import ControlPanel from "@/components/controlpanel";
import useLocations from "@/hooks/useLocations";
import { LoadingSpinner } from "@/components/ui/loadingSpinner";

export default function Tracking() {
  // location data for fleet
  const { data, center, zoom, loading } = useLocations();

  // single truck selected
  const [selectedAsset, setSelectedAsset] = useState(null);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen w-screen">
        <LoadingSpinner />
      </div>
    );
  }

  if (data) {
    return (
      <MapLayout>
        <ControlPanel>
          <TrackingPanel
            data={data}
            selectedAsset={selectedAsset}
            setSelectedAsset={setSelectedAsset}
          />
        </ControlPanel>
        <TrackingMap
          locations={data}
          center={center}
          zoom={zoom}
          selectedAsset={selectedAsset}
          setSelectedAsset={setSelectedAsset}
        />
      </MapLayout>
    );
  }
}
