// styles
import React from "react";
import "@/styles/index.css";

// components
import Navbar from "@/components/navbar";

// routing
import routes from "@/routes/RouteConfig";
import AdminRoute from "@/auth/AdminRoute";
import PrivateRoute from "@/auth/PrivateRoute";
import { AuthProvider } from "@/auth/AuthContext";
import { BrowserRouter, Route, Routes } from "react-router-dom";

// App
function App() {
  if (process.env.NODE_ENV === "production") {
    console.log = function () {};
    console.warn = function () {};
    console.error = function () {};
    // Add other console methods if needed
  }
  return (
    <AuthProvider>
      <BrowserRouter>
        <div className="app-bg flex flex-col h-screen lg:flex-row">
          <Navbar />
          <Routes>
            {routes.map((route, index) => {
              if (route.isPrivate) {
                return route.isAdmin ? (
                  <Route
                    key={index}
                    path={route.path}
                    element={
                      <AdminRoute>
                        {React.createElement(route.element)}
                      </AdminRoute>
                    }
                  />
                ) : (
                  <Route
                    key={index}
                    path={route.path}
                    element={
                      <PrivateRoute>
                        {React.createElement(route.element)}
                      </PrivateRoute>
                    }
                  />
                );
              } else {
                return (
                  <Route
                    key={index}
                    path={route.path}
                    element={React.createElement(route.element)}
                  />
                );
              }
            })}
          </Routes>
        </div>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
