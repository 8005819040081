import axios from "axios";
import { useAuth } from "@/auth/AuthContext";
import { useNavigate } from "react-router-dom";

import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
} from "@/components/ui/form";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";

const formSchema = z.object({
  username: z.string().min(2).max(50),
  password: z.string().min(8),
});

export default function LoginForm() {
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: { username: "", password: "" },
  });

  const { setAuthInfo } = useAuth();
  const navigate = useNavigate();

  async function onSubmit(values) {
    try {
      // Making a POST request to /api/auth/login/
      const response = await axios.post("/api/auth/login/", values);

      if (response.data && response.data.access && response.data.first_name) {
        setAuthInfo({
          token: response.data.access,
          firstName: response.data.first_name,
          isStaff: response.data.is_staff,
        });
        navigate("/");
      }
    } catch (error) {
      console.error("Login failed:", error);
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="bg-paper">
        <div className="mb-8 font-medium">
          YOU MUST LOGIN TO ACCESS THIS PAGE
        </div>
        <FormField
          control={form.control}
          name="username"
          render={({ field }) => (
            <FormItem className="mb-4">
              <FormLabel>Username</FormLabel>
              <FormControl>
                <Input {...field} placeholder="Username" />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem className="mb-8">
              <FormLabel>Password</FormLabel>
              <FormControl>
                <Input type="password" {...field} placeholder="Password" />
              </FormControl>
            </FormItem>
          )}
        />
        <Button type="submit">Login</Button>
      </form>
    </Form>
  );
}
