import GoogleMapReact from "google-map-react";
import React, { useEffect, useState } from "react";

// Map Component
// -------------------------------------------------------------------- //
export default function Map({ location }) {
  const [mapApi, setMapApi] = useState(null);
  const [mapInstance, setMapInstance] = useState(null);
  // map props
  const defaultProps = {
    center: {
      lat: location?.latitude || 0,
      lng: location?.longitude || 35.5,
    },
    zoom: 9,
  };

  useEffect(() => {
    if (mapApi && location) {
      new mapApi.Marker({
        position: { lat: location.latitude, lng: location.longitude },
        map: mapInstance,
      });
    }
  }, [location, mapApi, mapInstance]);

  const handleApiLoaded = (map, maps) => {
    setMapInstance(map);
    setMapApi(maps);
  };

  return (
    <div className="w-full h-[25vh]">
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyDHS1vzHhthX0r8z6NARZ8ofngg9boXHIU" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        yesIWantToUseGoogleMapApiInternals
        options={{ mapId: "79ba85b61acfbcf1", disableDefaultUI: true }}
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
      />
    </div>
  );
}
